import React, { FC, useContext, useRef } from "react";
import { graphql, Link } from "gatsby";
import { TipPageQuery, BlockFragment } from "../../graphql-types";
import { WavySection } from "../components/wavy-section";
import {
  colors,
  ySpaceRem,
  fontStacks,
  targetBaseFontSizePx,
  xSpaceRem,
} from "../global-style";
import { LayoutContext } from "../components/layout";
import { Center } from "../components/center";
import { css } from "styled-components";
import { Blocks } from "../components/blocks";
import { Footer } from "../components/footer";
import { SEO } from "../components/seo";
import Img, { FluidObject } from "gatsby-image";

type Props = {
  data: TipPageQuery;
};

const Tip: FC<Props> = props => {
  const { remInPx, waveClearance } = useContext(LayoutContext);
  const pageTitleRef = useRef<HTMLHeadingElement>(null);

  return (
    <>
      <SEO {...props.data.wp.tip?.seo} />

      <WavySection
        backgroundColor={colors.mediumBlue}
        style={{
          color: colors.white,
          paddingTop: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
          paddingBottom: `${ySpaceRem}rem`,
          transition: "padding 0.25s ease",
        }}
      >
        <Center>
          <div style={{ fontSize: "1.1rem" }}>
            <Link to="/" style={{ textDecoration: "underline" }}>
              Home
            </Link>{" "}
            >{" "}
            <Link to="/tips/" style={{ textDecoration: "underline" }}>
              Tips
            </Link>{" "}
            >{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: props.data.wp.tip?.title || "",
              }}
            />
          </div>
        </Center>
      </WavySection>

      <div
        style={{
          paddingTop: `${1.5 * ySpaceRem}rem`,
          paddingBottom: `calc(${1.5 * ySpaceRem}rem + ${waveClearance})`,
        }}
      >
        <Center>
          <div style={{ overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                margin: `-${0.5 * ySpaceRem}rem -${0.5 * xSpaceRem}rem`,
                alignItems: "start",
              }}
            >
              <div
                style={{
                  margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                  flexGrow: 999,
                  flexBasis: `${25 * xSpaceRem}rem`,
                }}
              >
                <div
                  style={{
                    ...(pageTitleRef.current &&
                      (() => {
                        const paddingYRem =
                          ySpaceRem / 2 -
                          ((pageTitleRef.current.getBoundingClientRect()
                            .height /
                            remInPx) %
                            (ySpaceRem / 2));

                        return {
                          paddingTop: `${0.5 * paddingYRem}rem`,
                          paddingBottom: `${0.5 * paddingYRem}rem`,
                        };
                      })()),
                    transition: "padding 0.25s ease",
                    marginBottom: `${ySpaceRem}rem`,
                  }}
                >
                  <h1
                    ref={pageTitleRef}
                    style={{
                      fontFamily: fontStacks.cubano,
                    }}
                    css={(() => {
                      const minFontSizeRem = 2;
                      const maxFontSizeRem = 2.8888;
                      const vwRangeStartPx = 500;

                      return css`
                        font-size: ${(minFontSizeRem *
                          (remInPx || targetBaseFontSizePx)) /
                          (vwRangeStartPx / 100)}vw;
                        line-height: 1;

                        @media (max-width: ${vwRangeStartPx}px) {
                          font-size: ${minFontSizeRem}rem;
                        }

                        @media (min-width: ${vwRangeStartPx *
                            (maxFontSizeRem / minFontSizeRem)}px) {
                          font-size: ${maxFontSizeRem}rem;
                        }
                      `;
                    })()}
                    dangerouslySetInnerHTML={{
                      __html: props.data.wp.tip?.title || "",
                    }}
                  />
                </div>

                <div style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                  <Img
                    fluid={
                      props.data.wp.tip?.featuredImage?.node?.localFile
                        ?.childImageSharp?.fluid as FluidObject
                    }
                    style={{ maxWidth: "28rem" }}
                  />
                </div>

                <Blocks blocks={props.data.wp.tip?.blocks as BlockFragment[]} />
              </div>

              <div
                style={{
                  margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                  flexGrow: 1,
                  flexBasis: `${11 * xSpaceRem}rem`,
                  padding: `${0.75 * ySpaceRem}rem`,
                  border: `3px solid ${colors.orange}`,
                  borderRadius: 8,
                }}
              >
                <h4
                  style={{
                    textTransform: "uppercase",
                    fontSize: "0.9rem",
                    marginBottom: `${0.25 * ySpaceRem}rem`,
                  }}
                >
                  Over deze maatregel
                </h4>

                <ul
                  style={{
                    marginBottom: `${1 * ySpaceRem}rem`,
                  }}
                  css={`
                    & > li + li {
                      margin-top: ${0.5 * ySpaceRem}rem;
                    }
                  `}
                >
                  {props.data.wp.tip?.tipCustomFields?.characteristicsTips
                    ?.filter(
                      edge => !edge?.characteristicCustomFields?.isEffect
                    )
                    .map((edge, i) => (
                      <li
                        key={i}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: `${2 * xSpaceRem}rem` }}>
                          <img
                            src={
                              edge?.characteristicCustomFields?.icon?.localFile
                                ?.publicURL || ""
                            }
                          />
                        </div>
                        {edge?.title}
                      </li>
                    ))}
                </ul>

                <h4
                  style={{
                    textTransform: "uppercase",
                    fontSize: "0.9rem",
                    marginBottom: `${0.25 * ySpaceRem}rem`,
                  }}
                >
                  Deze maatregel draagt bij aan
                </h4>

                <ul
                  css={`
                    & > li + li {
                      margin-top: ${0.5 * ySpaceRem}rem;
                    }
                  `}
                >
                  {props.data.wp.tip?.tipCustomFields?.characteristicsTips
                    ?.filter(edge => edge?.characteristicCustomFields?.isEffect)
                    .map((edge, i) => (
                      <li
                        key={i}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: `${2 * xSpaceRem}rem` }}>
                          <img
                            src={
                              edge?.characteristicCustomFields?.icon?.localFile
                                ?.publicURL || ""
                            }
                          />
                        </div>
                        {edge?.title}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </Center>
      </div>

      <Footer backgroundColor={colors.lightestGrey} />
    </>
  );
};

export default Tip;

export const pageQuery = graphql`
  query TipPage($id: ID!) {
    wp {
      tip(id: $id, idType: ID) {
        ...Tip
        blocks {
          ...Block
        }
        seo {
          ...SEO
        }
      }
    }
  }
`;
